import Cookies from '../../node_modules/js-cookie/src/js.cookie.js';
import $ from '../../node_modules/jquery/dist/jquery.min';

var FMScratch = FMScratch || {};

export default FMScratch.GDPR = {

    init:function(){
        console.log('GDPR init...');
        this.cookieName = "gdpr-cookies";
        this.cookieValue = "accepted";
        this.cookieBanner();

    },

    cookieBanner:function(){
        var _this = this;

        if(_this.checkCookie()){
            // console.log('found cookie!');
        }else{
            // console.log('no cookie!');
            _this.showOptIn();
            $('#cookie-alert>button').on('click', function(e){
                $(this).prop("disabled",true);
                _this.acceptCookies();
            });
        }
    },

    showOptIn:function(){
        // console.log('showOptIn');
        $('body').append('<div id="cookie-alert" ><p>This webpage uses cookies to improve functionality and performance. If you continue browsing the site, you are giving implied consent to the use of cookies on this website. See our <a href="/policies">policies</a> for details.</p><button class="close" aria-label="Close"></button></div>');
    },

    checkCookie:function(){
        // console.log('checking: ' + this.cookieName);
        if(Cookies.get(this.cookieName) == this.cookieValue){
            return true;
        }
        return false;
    },

    acceptCookies:function(){
        var _this = this;
        Cookies.set(_this.cookieName, _this.cookieValue, { expires: 30 });

        $('#cookie-alert').fadeOut('slow', function() {
            $(this).remove();
        });

    },


};
